var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":['Active', 'Sold'],"name":"Status","clearable":false,"searchable":false,"label":"label","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Property Type","label-for":"property-type"}},[_c('validation-provider',{attrs:{"name":"Property Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"Property Type","searchable":false,"clearable":false,"disabled":_vm.isreadonly,"options":['Rental']},model:{value:(_vm.data.property_type),callback:function ($$v) {_vm.$set(_vm.data, "property_type", $$v)},expression:"data.property_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.options.type,"name":"type","clearable":false,"searchable":false,"label":"label","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"MLS ID","label-for":"mls-id"}},[_c('validation-provider',{attrs:{"name":"MLS ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"mls-id","state":errors.length > 0 ? false:null,"name":"mls-id","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.mls_id),callback:function ($$v) {_vm.$set(_vm.data, "mls_id", $$v)},expression:"data.mls_id"}})],1)]}}])})],1),_c('b-form-group',{attrs:{"label":"Listed Date","label-for":"listed-date"}},[_c('validation-provider',{attrs:{"name":"Listed Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"listing-date","disabled":_vm.isreadonly,"locale":"en"},on:{"input":_vm.updateData},model:{value:(_vm.data.list_date),callback:function ($$v) {_vm.$set(_vm.data, "list_date", $$v)},expression:"data.list_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Listed Price","label-for":"listed-price"}},[_c('validation-provider',{attrs:{"name":"Listed Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"listed-price","state":errors.length > 0 ? false:null,"name":"listed-price","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.asking_price),callback:function ($$v) {_vm.$set(_vm.data, "asking_price", $$v)},expression:"data.asking_price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }