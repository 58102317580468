<template>
  <div class="component">
    <b-form-group
      label="Beds"
      label-for="beds"
    >
      <validation-provider
        #default="{ errors }"
        name="Beds"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="beds"
            v-model="data.bedrooms"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="beds"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Baths"
      label-for="baths"
    >
      <validation-provider
        #default="{ errors }"
        name="Baths"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="baths"
            v-model="data.bathrooms"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="baths"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Living Area"
      label-for="living-area"
    >
      <validation-provider
        #default="{ errors }"
        name="Living Area"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="living-area"
            v-model="data.floor_size"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="living-area"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  props: {
    data: Object,
    options: Object,
    isreadonly: Boolean,
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.data)
    },
  },
}
</script>

<style>

</style>
