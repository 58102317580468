<template>
  <div class="page">
    <validation-observer
      ref="registerForm"
      #default="{invalid}"
    >
      <div class="row">
        <div class="col-md-12">
          <b-card>
            <div
              v-if="singleListing.source !== 'RETS'"
              class="flex-justify-between"
            >
              <b-button
                v-b-modal.modal-import-listing
                variant="outline-secondary"
              >
                Import Listing by MLS
              </b-button>
              <b-button
                variant="outline-primary"
                type="submit"
                :disabled="invalid"
                @click="submit"
              >
                Update
              </b-button>
            </div>
            <b-tabs class="mt-2">
              <b-tab
                title="Listing"
                active
              >
                <div class="row">
                  <div class="col-md-3">
                    <b-card title="Add New Listing">
                      <Listing
                        :data="singleListing"
                        :options="options"
                        :isreadonly="singleListing.source === 'RETS'"
                        @update-data="updateData"
                      />
                    </b-card>
                  </div>
                  <div class="col-md-3">
                    <b-card
                      title="Rental"
                    >
                      <Rental
                        :data="singleListing"
                        :options="options"
                        :isreadonly="singleListing.source === 'RETS'"
                        @update-data="updateData"
                      />
                    </b-card>
                  </div>
                  <div class="col-md-3">
                    <b-card title="Area">
                      <Area
                        :data="singleListing"
                        :options="options"
                        :isreadonly="singleListing.source === 'RETS'"
                        update-data="updateData"
                      />
                    </b-card>
                  </div>
                  <div class="col-md-3">
                    <b-card title="Remark">
                      <Remark
                        :data="singleListing"
                        :isreadonly="singleListing.source === 'RETS'"
                        update-data="updateData"
                      />
                    </b-card>
                  </div>
                </div>

              </b-tab>
              <b-tab
                title="Images"
              >
                <Photo
                  :photo-list="getListingPhotos"
                  :source="getListingSource"
                  @select-photo="selectImgaeFromMedia"
                  @delete-photo="deleteImageFromMedia"
                />
                <!-- <vue-dropzone
                  id="dropzone"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-files-added="filesAdded"
                  @vdropzone-removed-file="fileDeleted"
                /> -->
              </b-tab>
              <b-tab
                title="Medias"
              >
                <Media
                  :media-data="media"
                  @update-data="updateMediaData"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>

      </div>
    </validation-observer>
    <b-modal
      id="modal-import-listing"
      ref="my-modal"
      title="Enter MLS ID"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :state="mlsState"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="MLS ID"
          label-for="mls-id"
          invalid-feedback="MLS ID is required"
        >
          <b-form-input
            id="mls-id"
            v-model="singleListing.mls_id"
            :state="mlsState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BButton, BTabs, BTab, BFormGroup, BFormInput,
} from 'bootstrap-vue'
// import vue2Dropzone from 'vue2-dropzone'
// import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Listing from './components/Listing.vue'
import Rental from './components/Rental.vue'
import Area from './components/Area.vue'
import Remark from './components/Remark.vue'
import Media from '../components/Media.vue'
import Photo from '../../components/Media/Photo.vue'

export default {
  components: {
    BCard,
    BButton,
    Listing,
    ValidationObserver,
    Rental,
    Area,
    Remark,
    BTab,
    BTabs,
    BFormGroup,
    BFormInput,
    Photo,
    // vueDropzone: vue2Dropzone,
    Media,
  },
  data() {
    return {
      required,
      mlsState: null,
      options: {},
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 300,
        maxFilesize: 0.5,
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
      },
      singleListing: {
        status: '',
        property_type: '',
        mls_id: '',
        list_date: '',
        class: 'RT_5',
        bedrooms: '',
        bathrooms: '',
        floor_size: '',
        area: '',
        subarea: '',
        address_unit: '',
        address_number: '',
        address_street: '',
        address_street_designation_id: '',
        address_direction: '',
        city: '',
        province: '',
        postal_code: '',
        remark: '',
        imageList: [],
        type: '',
        asking_price: '',
      },
      imageList: [],
      indexFrom: '',
      indexTo: '',
      media: [],
      selected: [],
    }
  },
  computed: {
    getListingPhotos() {
      return this.selected
    },
    getListingSource() {
      return this.singleListing.source
    },
  },
  created() {
    const { id } = this.$route.params

    this.$store.dispatch('listings/getSingleRentalListing', id).then(response => {
      if (response.code === 200) {
        this.singleListing = {
          system_id: response.data.system_id,
          status: response.data.status ? response.data.status : '',
          property_type: response.data.property_type ? response.data.property_type : '',
          mls_id: response.data.mls_id ? response.data.mls_id : '',
          list_date: response.data.list_date ? response.data.list_date : '',
          class: response.data.class ? response.data.class : '',
          bedrooms: response.data.bedrooms ? response.data.bedrooms : '',
          bathrooms: response.data.bathrooms ? response.data.bathrooms : '',
          floor_size: response.data.floor_size ? response.data.floor_size : '',
          area: response.data.area ? response.data.area : '',
          subarea: response.data.subarea ? response.data.subarea : '',
          address_unit: response.data.address_unit ? response.data.address_unit : '',
          address_number: response.data.address_number ? response.data.address_number : '',
          address_street: response.data.address_street ? response.data.address_street : '',
          address_street_designation_id: response.data.address_street_designation_id ? response.data.address_street_designation_id : '',
          address_direction: response.data.address_direction ? response.data.address_direction : '',
          city: response.data.city ? response.data.city : '',
          province: response.data.province ? response.data.province : '',
          postal_code: response.data.postal_code ? response.data.postal_code : '',
          remark: response.data.remark ? response.data.remark : '',
          imageList: response.data.imageList ? response.data.imageList : '',
          type: response.data.type ? response.data.type : '',
          asking_price: response.data.asking_price ? response.data.asking_price : '',
          sold_price: response.data.sold_price ? response.data.sold_price : '',
          photos: response.data.photos ? response.data.photos : [],
        }

        this.selected = response.data.photos ? response.data.photos : []
        this.imageList = []

        if (response.data.embed) {
          this.media = JSON.parse(response.data.embed)
        }
        this.getOptions(this.singleListing.class)
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    selectImgaeFromMedia(list) {
      // this.data.imageList = list
      this.selected = list
      // TODO: import 할때 신경쓰기
    },
    deleteImageFromMedia(single) {
      const target = this.selected.indexOf(single)
      this.selected.splice(target, 1)
    },
    updateData(data) {
      this.singleListing = data
    },
    updateMediaData(data) {
      this.media = data
    },
    submit() {
      if (this.singleListing.source === 'RETS') {
        const mediaData = {
          system_id: this.$route.params,
          embed: this.media,
        }

        this.$store.dispatch('listings/addRentalMedia', mediaData).then(response => {
          if (response.code === 200) {
            this.showToast('Listing Updated', 'CheckCircleIcon', 'Listing has been updated successfully', 'success', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      } else {
        if (this.singleListing.class === null) {
          this.singleListing.class = 'RT_5'
        }

        if (this.selected.length > 0) {
          this.singleListing.photos = []
          this.singleListing.imageList = []

          this.selected.forEach(img => {
            if (typeof img === 'object') {
              if (img.file_path) {
                this.singleListing.photos.push(img.file_path)
                this.singleListing.imageList.push(img.file_path)
              } else if (img.location) {
                this.singleListing.photos.push(img.location)
                this.singleListing.imageList.push(img.location)
              }
            } else {
              this.singleListing.photos.push(img)
              this.singleListing.imageList.push(img)
            }
          })
        }

        if (this.media.length > 0) {
          this.singleListing.embed = this.media
        } else {
          this.singleListing.embed = []
        }

        
        if(this.singleListing.asking_price) {
          this.singleListing.asking_price =  this.singleListing.asking_price.toString()
        }

        if (this.singleListing.sold_price) {
          this.singleListing.sold_price = this.singleListing.sold_price.toString()
        }

        if(this.singleListing.bathrooms ) {
          this.singleListing.bathrooms = this.singleListing.bathrooms.toString()
        }
        
        if(this.singleListing.bedrooms) {
          this.singleListing.bedrooms = this.singleListing.bedrooms.toString()
        }

        this.$store.dispatch('listings/updateRentalListing', this.singleListing).then(response => {
          if (response.code === 200) {
            this.showToast('Listing Updated', 'CheckCircleIcon', 'Listing has been updated successfully', 'success', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.mlsState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.mlsState = null
    },
    handleOk(bvModalEvt) {
      this.handleSubmit(bvModalEvt)
    },
    handleSubmit(bvModalEvt) {
      if (!this.checkFormValidity()) {
        bvModalEvt.preventDefault()
        // eslint-disable-next-line no-useless-return
        return
      }
    },
    getOptions(className) {
      const data = {
        options: Object.keys(this.singleListing),
        class: className !== null ? className : 'RT_5',
      }
      this.$store.dispatch('listings/getOptionsList', data).then(response => {
        if (response.code === 200) {
          this.options = response.data
        }
      }).catch(err => {
        console.error(err)
      })
    },
    filesAdded(file) {
      file.forEach(data => {
        if (data.size < 1024 * 500) {
          const newData = data
          newData.isNew = 1
          this.imageList.push(newData)
        } else {
          this.$refs.myVueDropzone.removeFile(data)

          this.showToast('Upload Failed', 'AlertCircleIcon', data.name + ' exceeds the maximum file size (500KB)', 'danger', 'bottom-right')
        }
      })
    },
    fileDeleted(file) {
      this.imageList = this.imageList.filter(data => data.upload.uuid !== null && data.upload.uuid !== file.upload.uuid)
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
.vs__open-indicator{
  display:none !important;
}

.dz-size {
  display: none;
}

.dz-remove {
  top: unset !important;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
